import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wins',
    name: 'Wins',
    component: () => import(/* webpackChunkName: "about" */ '../components/Wins')
  },
  {
    path: '/resheniya',
    name: 'Ebsa',
    component: () => import(/* webpackChunkName: "about" */ '../components/Ebsa')
  },
  {
    path: '/chance',
    name: 'chance',
    component: () => import(/* webpackChunkName: "about" */ '../components/chance')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
